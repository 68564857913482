<template>
  <img :src="source" />
</template>

<script>
import { getGameMediaByIndex } from '@/firebase'

export default {
  name: "ModelImage",
  props: {
    model: {
      type: Object,
      required: true,
    },
    mediaIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      source: null,
    }
  },
  methods: {
    async getGameMediaURL(game, mediaIndex) {
      this.source = await getGameMediaByIndex(game, mediaIndex)
    },
  },
  created() {
    this.getGameMediaURL(this.model, this.mediaIndex)
  }
}
</script>