<template>
  <Transition>
    <section class="new-releases" v-show="mediaLoaded">
      <header>
        <h1>Featured games</h1>
        <div class="carousel-indicators">
          <div :class="['carousel-indicator', { 'active': this.selectedGame === game }]" v-for="game in featuredGames" :key="game.name" :onclick="() => carouselSelect(game)"></div>
        </div>
      </header>
      <div v-if="this.selectedGame" class="selected-game">
        <div class="thumbnail-wrapper">
          <ModelImage
            v-if="!mock"
            :model="this.selectedGame"
            :mediaIndex="this.selectedGame.activeImage" />
          <img v-else :src="`/images/games-showcase/${this.selectedGame.images[this.selectedGame.activeImage]}`" :alt="this.selectedGame.name" />
          <div class="golden-tag" v-if="this.selectedGame.gameDevTeam">
            <div class="inner-wrapper">
              <span>Game Dev Team</span>
            </div>
          </div>
        </div>
        <div class="game-content-wrapper">
          <h2><router-link :to="getGameLink(this.selectedGame)">{{ this.selectedGame.name }}</router-link></h2>
          <ul class="tags">
            <li v-for="tag in this.selectedGame.tags" :key="tag">{{ tag }}</li>
          </ul>
          <h3>Description</h3>
          <p class="description">{{ this.selectedGame.description }}</p>
          <div class="image-feed-wrapper">
            <ModelImage
              v-if="!mock"
              :class="{ 'active': index === this.selectedGame.activeImage }"
              v-for="(image, index) in this.selectedGame.images"
              :key="`sg-${index}`"
              :model="this.selectedGame"
              :mediaIndex="index"
              :onclick="() => this.selectedGame.activeImage = index" />
            <img
              v-else
              :class="{ 'active': index === this.selectedGame.activeImage }"
              v-for="(image, index) in this.selectedGame.images"
              :key="`sgm-${index}`"
              :src="`/images/games-showcase/${image}`"
              :onclick="() => this.selectedGame.activeImage = index" />
          </div>
        </div>
      </div>
    </section>
  </Transition>
  <Transition>
    <section class="all-games middle" v-show="mediaLoaded">
      <h1>All games</h1>
      <div class="all-games-grid">
        <div v-for="(game, i) in this.games" :key="game.name">
          <router-link :to="getGameLink(game)"><div class="cover">
            <ModelImage
              v-if="!mock"
              :model="game"
              :mediaIndex="0"
              :ref="`game-${i+1}`" />
            <img v-else :src="`/images/games-showcase/${game.thumbnail ? game.thumbnail : game.images[0]}`" :alt="`${game.name} thumbnail`" />
            <span class="tag" v-if="game.new">New</span>
            <span class="tag gold" v-if="game.gameDevTeam">Game Dev Team</span>
          </div></router-link>
          <span class="name"><router-link :to="getGameLink(game)">{{ game.name }}</router-link></span>
        </div>
      </div>
    </section>
  </Transition>
</template>

<script>
import { getGamesList } from '../firebase';
import ModelImage from '@/components/admin/ModelImage'
import { useDevLog } from '../util';

const { devLog } = useDevLog()

export default {
  name: "GamesShowcaseOverview",
  components: {
    ModelImage,
  },
  data() {
    return {
      mock: true,
      games: [],
      selectedGame: null,
      mediaLoaded: false,
    }
  },
  mounted() {
    // Set games to store data
    if (this.mock) {
      // Set games to reversed store data
      this.games = [...this.$store.state.games]
      this.games = this.games.reverse()
      this.selectedGame = this.featuredGames[0]
      this.mediaLoaded = true
    } else {
      this.mountGames()
    }
  },
  computed: {
    featuredGames() {
      return this.games.filter(game => game.id === 3 || game.id === 8)
    }
  },
  methods: {
    carouselSelect(game) {
      const selectedGame = this.games.find(g => g === game)
      if (selectedGame) {
        this.selectedGame = selectedGame
      }
    },
    getGameLink(game) {
      return `/game/${game.id}`
    },
    onDataChange(list) {
      let games = []

      list.forEach((item, index) => {
        games.push({
          id: index,
          ...item,
          activeImage: 0,
        })
      })
      this.games = games
    },
    mountGames() {
      getGamesList(list => {
        this.onDataChange(list)
        if (this.games.length > 0) {
          this.selectedGame = this.games[0]
        }
      })

      // Every 100ms, check if the last game has a source. If it does, clear the interval and display the page.
      const interval = setInterval(() => {
        const lastGame = this.$refs[`game-${this.games.length}`]
        if (!lastGame) return

        devLog('Last game exists.')

        // If last game exist, check it's source. If not available, start watching it.
        if (lastGame.source) {
          devLog('Source found! Setting media to loaded and clearing interval...')
          this.mediaLoaded = true
          clearInterval(interval)
        } else {
          this.$watch(() => lastGame.source, (val) => {
            if (val) {
              devLog('Source found! Setting media to loaded')
              this.mediaLoaded = true
            }
          })
          devLog("Source not found. Set up watcher and clearing interval...")
          clearInterval(interval)
        }
      }, 50)
    }
  }
};
</script>

<style scoped lang="scss">
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
a {
  color: #fff;
  text-decoration: none;
  opacity: 1;
  transition: opacity 150ms ease;

  &:hover {
    opacity: 0.6;
  }
}

.new-releases {
  header {
    display: flex;
    align-items: center;
    gap: 3rem;
    margin-bottom: 1.5rem;
  }

  .carousel-indicators {
    display: flex;
    gap: 1rem;
  }

  .carousel-indicator {
    width: 48px;
    height: 6px;
    background-color: #fff;
    opacity: 0.4;
    border-radius: 10px;
    transition: all 200ms ease;
    cursor: pointer;

    &.active {
      width: 96px;
      opacity: 1;
    }
  }

  .selected-game {
    display: flex;
    gap: 4rem;

    h2 {
      font-size: 2rem;
    }

    .thumbnail-wrapper {
      position: relative;
      flex-basis: 60%;
      width: 100%;
      aspect-ratio: 16/9;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }

      .golden-tag {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        top: 0;
        width: 180px;
        height: 40px;
        background: linear-gradient(90deg, #fd9f13, #fcb045, #e8c64b);
        font-weight: 500;
        text-transform: uppercase;
        border-radius: 10px 0 10px 0;
        overflow: hidden;

        &::before {
          content: '';
          position: absolute;
          width: 110%;
          aspect-ratio: 1/1;
          background-image: conic-gradient(transparent, transparent, transparent, #f4ecce);
          animation: glow 4s linear infinite;
        }

        &::after {
          content: '';
          position: absolute;
          width: 110%;
          aspect-ratio: 1/1;
          background-image: conic-gradient(transparent, transparent, transparent, #f4ecce);
          animation: glow 4s linear infinite;
          animation-delay: -2s;
        }

        .inner-wrapper {
          position: absolute;
          z-index: 1;
          inset: 2px;
          background: linear-gradient(90deg, #fd9f13, #fcb045, #e8c64b);
          border-radius: 10px 0 10px 0;

          span {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            white-space: nowrap;
          }
        }

        @keyframes glow {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }

    .game-content-wrapper {
      flex-basis: 40%;
    }

    .tags {
      display: block;
      padding: 0;
      margin: 1rem 0;

      li {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 12px;
        padding: 7px 12px;
        background-color: #444455;
        list-style-type: none;
        border-radius: 4px;
        font-size: 14px;
      }
    }

    .description {
      margin-top: 0.75rem;
      opacity: 0.5;
      line-height: 1.3;
    }

    .image-feed-wrapper {
      display: flex;
      gap: 14px;
      margin-top: 2rem;

      img {
        flex-basis: 34%;
        width: 10%;
        border-radius: 8px;
        aspect-ratio: 16/9;
        object-fit: cover;
        border: 3px solid rgba(0,0,0,0);
        outline: 1px solid rgba(255, 255 ,255, 0);
        opacity: 0.6;
        cursor: pointer;
        transition: opacity 200ms ease, outline 200ms ease;

        &.active {
          opacity: 1;
          outline: 1px solid rgba(255, 255, 255, 1);
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    header {
      display: block;

      .carousel-indicators {
        margin-top: 1rem;
      }
    }

    .selected-game {
      display: block;

      .game-content-wrapper {
        margin-top: 2rem;
      }
    }
  }
}

.all-games-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 2rem;
  margin-top: 2rem;

  .cover {
    position: relative;

    img {
      aspect-ratio: 2/3;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }

    .tag {
      position: absolute;
      left: 0;
      top: 1rem;
      text-transform: uppercase;
      background-color: #f00;
      padding: 6px 1rem;
      border-radius: 0 1rem 1rem 0;

      &.gold {
        background: linear-gradient(90deg, #fd9f13, #fcb045, #e8c64b);
      }
    }
  }

  .name {
    display: inline-block;
    margin-top: 6px;
    font-weight: 500;
  }
}

@media screen and (max-width: 900px) {
  .all-games-grid {
    grid-template-columns: repeat(auto-fill, minmax(128px, 1fr));
  }
}
</style>